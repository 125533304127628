import Bedahumum from "./Bedahumum";
import {Helmet} from "react-helmet";
import { HashRouter as Router, Routes,Route } from "react-router-dom"
import ArticleWrap from "./pages/ArticlesWrap";
import SingleWrap from "./pages/SingleWrap";
import React, { useRef } from "react";
import { NotFound } from "./pages/NotFound";
import Navbar from "./components/Navbar";
import Intro from "./pages/Intro";



// import Home from "./components/Home";

// import Igs from "./components/Instagrams"
// import Trainings from "./components/Trainings";
// import Work from "./components/Work";
// import Profile from "./components/Profile";
// import Gallery from "./components/Gallery";
// import Speciality from "./components/Speciality";
// 
// import { FloatingWhatsApp  } from 'react-floating-whatsapp'
// import avt from './/assets/images/avatar/avatarsupport.png'
// import logo from './assets/metaimg.jpg'


function App() {
  //const metaDecor = require("./metaDecor");
  const resultRef = useRef(null);
  // const resultRef = useRef(null);

  return (

    <div>
    <Helmet>
          <meta charSet="utf-8" />
          <title>BedahUmum.com</title>
          <link rel="Bedahumum" href="https://bedahumum.com/" />
          <meta name="Bedah umum " content="Konsultasi Online Gratis" />
          
          <meta property="og:type" content="website" />
          <meta property="og:url" content="Bedahumum.com" />
          <meta property="og:title" content="Konsultasi gratis dengan dokter bedah umum - Bedahumum.com" />
          <meta property="og:description" content="Konsultasi Online Gratis" />
          <meta property="og:image" content="'https://bedahumum.com/doctors.png" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="Bedahumum.com" />
          <meta property="twitter:title" content="Konsultasi gratis dengan dokter bedah umum - Bedahumum.com" />
          <meta property="twitter:description" content="Konsultasi Online Gratis" />
          <meta property="twitter:image" content="'https://bedahumum.com/doctors.png" />

    </Helmet>

    {/* <Navbar />
      <Home resultRef={resultRef}/>
      <Igs ref = {resultRef}/>
      <Profile ref = {resultRef}/>
      <Speciality ref = {resultRef}/>
      <Work  />
      <Trainings/>
      <Gallery/>
      <FloatingWhatsApp  
        phoneNumber="+628170138883"
        accountName="Admin"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={avt}
        /> */}
      
  
      <Router>
      <Navbar/>
      
        <Routes>
          <Route path="/" element={<Bedahumum resultRef={resultRef} />} />
          <Route path="/article" element={<ArticleWrap />}/>
          <Route path="/posts/:id" element={<SingleWrap />} />
          <Route path="/intro" element={<Intro />}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
