import React, { forwardRef, useState } from "react";
import drtonyprofile from "../assets/drtonyprofile.png";
import drhermanprofile from "../assets/drhermanprofile.png";
import drmarlopprofile from "../assets/drmarolopprofile.png";
import { RxReset } from "react-icons/rx";

const Profile = forwardRef((props, ref) => {
  const [show, setShow] = useState(true);
  const [showtony, setShowtony] = useState(false);
  const [showherman, setShowherman] = useState(false);
  const [showmarolop, setShowmarolop] = useState(false);

  function showhide(index) {
    if (index === 0) {
      setShow(true);
      setShowtony(false);
      setShowherman(false);
      setShowmarolop(false);
    } else if (index === 1) {
      setShow(false);
      setShowtony(true);
      setShowherman(false);
      setShowmarolop(false);
    } else if (index === 2) {
      setShow(false);
      setShowtony(false);
      setShowherman(true);
      setShowmarolop(false);
    } else {
      setShow(false);
      setShowtony(false);
      setShowherman(false);
      setShowmarolop(true);
    }
  }
  return (
    <div
      ref={ref}
      name="profile"
      className="w-full h-screen bg-[#BBD6B8] text-gray-700"
    >
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4 ">
            <p className="text-4xl font-bold inline border-b-4 border-green-600">
              Profile
            </p>
          </div>
          <div></div>
        </div>
        {/* <div>
          {show ? <h1>hello</h1> : null}
          <button onClick={() => setShow(!show)}>toggle</button>
        </div> */}
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4 ">
          {show ? (
            <div className="text-justify">
              <p>
                Kami sangat bangga memperkenalkan tim dokter berpengalaman yang
                siap memberikan perawatan terbaik untuk Anda dan keluarga.{" "}
              </p>
              <p></p>
              <br></br>
              <p>
                Setiap dokter memiliki spesialisasi dan keahlian yang berbeda,
                memastikan Anda mendapatkan layanan kesehatan yang sesuai dengan
                kebutuhan Anda.
              </p>
              <p></p>
              <br></br>
              <p>
                Untuk mengetahui lebih lanjut tentang setiap dokter, silakan
                klik pada foto mereka. Anda akan menemukan informasi lengkap
                mengenai latar belakang, pengalaman, dan bidang keahlian
                masing-masing dokter.{" "}
              </p>
              <br></br>
            </div>
          ) : null}
          {showtony ? (
            <div className="text-justify">
              <p>
                <b>dr Tony Sukentro Sp.B </b>, was born in Jakarta in July 1974.{" "}
              </p>
              <p>
                Graduated from medical school at Atmajaya University, Jakarta,
                Indonesia and continued my master degree at Faculty of Medicine
                Department of Surgery, University of Indonesia, Jakarta,
                Indonesia
              </p>
              <p></p>
              <br></br>
              <p>
                Right now working at EMC Hospital, Harapan Bunda Hospital, and
                ST Wasir Center Clinic in Jakarta Indonesia. I'm also attended a
                lot of training and held certain positions outside my status as
                a Doctor.
              </p>
              <br></br>

              <div className="mx-auto flex flex-row items-baseline">
                <p>Please see more information about me on this website</p>
                <br />
                <RxReset onClick={() => showhide(0)} />
                <p></p>
              </div>
            </div>
          ) : null}
          {showherman ? (
            <div className="text-justify">
              <p>
                <b>dr. Herman Sp.OT </b>is an Orthopedic Specialist Doctor. He
                graduated as a Specialist in Orthopedics and Traumatology at the
                Faculty of Medicine, University of Indonesia in 2007. He also
                took part in a Spine fellowship at the University of Indonesia
                and Tohoku University in Sendai, Japan in 2008.{" "}
              </p>
              <br></br>
              <p>
                <b>dr. Herman Gofara, Sp.OT</b> is a member of the Indonesian
                Doctors Association (IDI) and the Association of Indonesian
                Orthopedic & Traumatology Specialist Doctors (PABOI). The
                services he provides include: Surgical consultation specifically
                for sufferers of spinal disorders.
              </p>
              <br></br>
              <div className="mx-auto flex flex-row items-baseline">
                <p>Please see more information about me on this website</p>
                <br />
                <RxReset onClick={() => showhide(0)} />
                <p></p>
              </div>
            </div>
          ) : null}
          {showmarolop ? (
            <div className="text-justify">
              <p>
                <b>dr. Marolop Pardede Sp.BTKV </b>is a Thoracic and
                Cardiovascular Surgeon Specialist who completed his specialist
                education at the Faculty of Medicine, University of Indonesia.
                He is also a member of the Indonesian Doctors Association (IDI).{" "}
              </p>
              <br></br>
              <p>
                The services provided by Dr. Marolop Pardede, Sp.BTKV includes:
                Consultation before surgery on the chest and heart..
              </p>
              <br></br>
              <div className="mx-auto flex flex-row items-baseline">
                <p>Please see more information about me on this website</p>
                <br />
                <RxReset onClick={() => showhide(0)} />
                <p></p>
              </div>
            </div>
          ) : null}
          <div className=" grid grid-cols-3 gap-5">
            <div className="items-center justify-center text-center ">
              <button>
                <img
                  className="shadow-[#94AF9F] hover:scale-110 duration-500 pt-2"
                  src={drtonyprofile}
                  alt="drtonyprofile"
                  onClick={() => showhide(1)}
                />
              </button>
              <div className="text-xs my-2 bg-slate-100 w-full">
                <p className="text-xs my-2 text-wrap">dr.Tony Sukentro Spb</p>
              </div>
            </div>
            <div className="items-center justify-center text-center">
              <button>
                <img
                  className="shadow-[#94AF9F] hover:scale-110 duration-500 pt-2"
                  src={drhermanprofile}
                  alt="drhermanprofile"
                  onClick={() => showhide(2)}
                />
              </button>
              <div className="text-xs my-2 bg-slate-100 w-full">
                <p className="text-xs my-2 text-wrap">dr.Herman Gofara Sp.OT</p>
              </div>
            </div>
            <div className="items-center justify-center text-center">
              <button>
                <img
                  className="shadow-[#94AF9F] hover:scale-110 duration-500 pt-2"
                  src={drmarlopprofile}
                  alt="drmarlopprofile"
                  onClick={() => showhide(3)}
                />
              </button>
              <div className="text-xs my-2 bg-slate-100 w-full">
                <p className="text-xs my-2">dr.Marolop Pardede Sp.BTKV</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Profile;
